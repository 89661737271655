import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';
import styled from 'styled-components';

const ImageGallery = styled.section`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 0;
  @media (max-width: 767px) {
    grid-template-columns: 1fr;
  }
`;

const imageGrid = () => {
  return (
    <ImageGallery>
      <StaticImage
        src="../images/QP1/qp1-1.jpg"
        alt="Quinta dos Pastores I - Exterior 1"
      />
      <StaticImage
        src="../images/QP1/qp1-2.jpg"
        alt="Quinta dos Pastores I - Sala de Refeições"
      />
      <StaticImage
        src="../images/QP1/qp1-3.jpg"
        alt="Quinta dos Pastores I - Exterior 2"
      />
      <StaticImage
        src="../images/QP1/qp1-4.jpg"
        alt="Quinta dos Pastores I - Cozinha"
      />
      <StaticImage
        src="../images/QP1/qp1-5.jpg"
        alt="Quinta dos Pastores I - Quarto"
      />
      <StaticImage
        src="../images/QP1/qp1-6.jpg"
        alt="Quinta dos Pastores I - Snooker"
      />
    </ImageGallery>
  );
};

export default imageGrid;
