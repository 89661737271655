import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import styled from 'styled-components';
import BackgroundImage from 'gatsby-background-image';

import icon1 from '../images/hero-icons/qp1/1.svg';
import icon2 from '../images/hero-icons/qp1/2.svg';
import icon3 from '../images/hero-icons/qp1/3.svg';
import icon4 from '../images/hero-icons/qp1/4.svg';
import icon5 from '../images/hero-icons/qp1/5.svg';
import snookerIcon from '../images/hero-icons/qp2/5.svg';
import icon6 from '../images/hero-icons/qp1/6.svg';
import icon7 from '../images/hero-icons/qp1/7.svg';
import icon8 from '../images/hero-icons/qp1/8.svg';
import icon9 from '../images/hero-icons/qp1/9.svg';
import icon10 from '../images/hero-icons/qp1/10.svg';
import icon11 from '../images/hero-icons/qp1/11.svg';
import icon12 from '../images/hero-icons/qp1/12.svg';
import icon13 from '../images/hero-icons/qp1/13.svg';
import icon14 from '../images/hero-icons/qp1/14.svg';

const StyledBackgroundSection = styled(BackgroundImage)`
  width: 100%;
  background-size: contain;
  z-index: 1;
  background-position: bottom right;
  background-repeat: no-repeat;
`;
const IntroSection = styled.section`
  position: relative;
  background: #fff5e8;
  .text-padding {
    padding: 5.875rem 2rem;
  }
  p {
    font-size: 1.25rem;
    line-height: 1.5;
    font-weight: 500;
    margin-bottom: 1rem;
    color: #722e00;
  }
`;

const IconList = styled.div`
  color: #722e00;
  font-weight: 500;
  font-size: 1.125rem;
  line-height: 3rem;
  text-align: center;
  span {
    display: inline-block;
    margin-right: 1rem;
  }
  img {
    height: 20px;
    margin-right: 0.5rem;
  }
`;

const DesktopVersion = styled.div`
  @media (max-width: 1023px) {
    display: none;
  }
`;

const MobileVersion = styled.div`
  p {
    text-align: center;
    max-width: 70ch;
    margin-left: auto;
    margin-right: auto;
  }
  @media (min-width: 1024px) {
    display: none;
  }
`;

const Intro = () => {
  const data = useStaticQuery(
    graphql`
      query {
        qp: file(relativePath: { eq: "intro-qp1.jpg" }) {
          childImageSharp {
            fluid(quality: 80, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `
  );
  const qp = data.qp.childImageSharp.fluid;
  return (
    <IntroSection>
      <DesktopVersion>
        <StyledBackgroundSection
          Tag="div"
          fluid={qp}
          backgroundColor={`#fff5e8`}
        >
          <div className="container">
            <div className="grid">
              <div className="col-md-6">
                <div className="text-padding">
                  <p>
                    Uma quinta com cerca de 3000m<sup>2</sup>, com uma vivenda
                    de 120m<sup>2</sup>, uma piscina e um anexo exterior de 50m
                    <sup>2</sup>.
                  </p>
                  <p>
                    Quinta totalmente privada e exclusiva (não haverão outros
                    hóspedes na propriedade).
                  </p>
                  <p>
                    A vivenda dispõe de <strong>uma sala grande</strong>,{' '}
                    <strong>cozinha</strong> e <strong>3 quartos</strong>. Além
                    disso, existe um{' '}
                    <strong>salão exterior para almoços</strong>, assim como um{' '}
                    <strong>barbecue para cozinhar um churrasco</strong>.
                  </p>
                  <p>
                    Uma das atracções da <strong>Quinta dos Pastores I</strong>{' '}
                    são os nossos <strong>animais</strong> e o nosso{' '}
                    <strong>pomar</strong>. Temos cabrinhas, ovelhas, borregos,
                    pavões, faisões, patos, galinhas, codornizes e até diversas
                    árvores de frutos.
                  </p>
                  <p>
                    Por último, a quinta é{' '}
                    <strong>totalmente vedada e pet-friendly</strong>, pelo que
                    os vossos animais serão bem vindos.
                  </p>
                  <IconList>
                    <span>
                      <img src={icon1} alt="170m2" /> 170 m
                      <sup>
                        <small>2</small>
                      </sup>
                    </span>
                    <span>
                      <img src={icon2} alt="Quartos" /> 3 Quartos
                    </span>
                    <span>
                      <img src={icon3} alt="Piscina" /> Piscina de Água Salgada
                    </span>
                    <span>
                      <img src={icon4} alt="Churrasco" /> Churrasco
                    </span>
                    <span>
                      <img src={icon5} alt="Pessoas" /> Até 10 Pessoas
                    </span>
                    <span>
                      <img src={icon6} alt="Bicicletas" /> Bicicletas
                    </span>
                    <span>
                      <img src={icon7} alt="Jogos" /> Jogos
                    </span>
                    <span>
                      <img src={snookerIcon} alt="Snooker" /> Snooker
                    </span>
                    <span>
                      <img src={icon8} alt="Casa na Árvore" /> Casa na Árvore
                    </span>
                    <span>
                      <img src={icon9} alt="Trampolim" />
                      Trampolim
                    </span>
                    <span>
                      <img src={icon10} alt="Baloiço" /> Baloiço
                    </span>
                    <span>
                      <img src={icon11} alt="Horta" /> Horta
                    </span>
                    <span>
                      <img src={icon12} alt="Animais" /> Animais
                    </span>
                    <span>
                      <img src={icon13} alt="Lareira" /> Ar Condicionado /
                      Lareira
                    </span>
                    <span>
                      <img src={icon14} alt="Sauna" /> Sauna
                    </span>
                  </IconList>
                </div>
              </div>
              <div className="col-md-6"></div>
            </div>
          </div>
        </StyledBackgroundSection>
      </DesktopVersion>
      <MobileVersion>
        <div className="container">
          <div className="text-padding">
            <p>
              Uma quinta com cerca de 3000m<sup>2</sup>, com uma vivenda de 120m
              <sup>2</sup>, uma piscina e um anexo exterior de 50m
              <sup>2</sup>.
            </p>
            <p>
              Quinta totalmente privada e exclusiva (não haverão outros hóspedes
              na propriedade).
            </p>
            <p>
              A vivenda dispõe de <strong>uma sala grande</strong>,{' '}
              <strong>cozinha</strong> e <strong>3 quartos</strong>. Além disso,
              existe um <strong>salão exterior para almoços</strong>, assim como
              um <strong>barbecue para cozinhar um churrasco</strong>.
            </p>
            <p>
              Uma das atracções da <strong>Quinta dos Pastores I</strong> são os
              nossos <strong>animais</strong> e o nosso <strong>pomar</strong>.
              Temos cabrinhas, ovelhas, borregos, pavões, faisões, patos,
              galinhas, codornizes e até diversas árvores de frutos.
            </p>
            <p>
              Por último, a quinta é{' '}
              <strong>totalmente vedada e pet-friendly</strong>, pelo que os
              vossos animais serão bem vindos.
            </p>
            <IconList>
              <span>
                <img src={icon1} alt="170m2" /> 170 m
                <sup>
                  <small>2</small>
                </sup>
              </span>
              <span>
                <img src={icon2} alt="Quartos" /> 3 Quartos
              </span>
              <span>
                <img src={icon3} alt="Piscina" /> Piscina de Água Salgada
              </span>
              <span>
                <img src={icon4} alt="Churrasco" /> Churrasco
              </span>
              <span>
                <img src={icon5} alt="Pessoas" /> Até 10 Pessoas
              </span>
              <span>
                <img src={icon6} alt="Bicicletas" /> Bicicletas
              </span>
              <span>
                <img src={icon7} alt="Jogos" /> Jogos
              </span>
              <span>
                <img src={snookerIcon} alt="Snooker" /> Snooker
              </span>
              <span>
                <img src={icon8} alt="Casa na Árvore" /> Casa na Árvore
              </span>
              <span>
                <img src={icon9} alt="Trampolim" />
                Trampolim
              </span>
              <span>
                <img src={icon10} alt="Baloiço" /> Baloiço
              </span>
              <span>
                <img src={icon11} alt="Horta" /> Horta
              </span>
              <span>
                <img src={icon12} alt="Animais" /> Animais
              </span>
              <span>
                <img src={icon13} alt="Lareira" /> Ar Condicionado / Lareira
              </span>
              <span>
                <img src={icon14} alt="Sauna" /> Sauna
              </span>
            </IconList>
          </div>
        </div>
      </MobileVersion>
    </IntroSection>
  );
};

export default Intro;
