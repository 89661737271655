import React, { useEffect, useContext } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Seo from '../components/seo';
import MenuContext from '../context/MenuContext';
import CallBtn from '../components/callBtn';
import QpHeader from '../components/qp1Header';
import Intro from '../components/intro-qp1';
import ImageGrid from '../components/imageGrid-QP1';
import WhatToDo from '../components/whatTodo-QP1';
import Reviews from '../components/reviews-QP1';
import Animals from '../components/animals';
import ContactForm from '../components/contactForm';
import Layout from '../components/layout';

const IndexPage = () => {
  const data = useStaticQuery(
    graphql`
      query {
        qp: file(relativePath: { eq: "contact-bg-qp1.jpg" }) {
          childImageSharp {
            fluid(quality: 80, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `
  );
  const qp = data.qp.childImageSharp.fluid;
  const menu = useContext(MenuContext);
  const title = 'Quinta dos Pastores I';
  const tagline =
    'A sua quinta privada favorita para umas férias em família, junto da natureza, de animais e com um pomar repleto de frutos.';

  useEffect(() => {
    if (menu.show) {
      menu.toggleShow();
    }
  }, []);
  return (
    <Layout>
      <Seo title={title} description={tagline} />
      <CallBtn />
      <QpHeader title={title} tagline={tagline} color="#586141" />
      <Intro />
      <ImageGrid />
      <WhatToDo />
      <Reviews />
      <Animals />
      <ContactForm background={qp} />
    </Layout>
  );
};

export default IndexPage;
