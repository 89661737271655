import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import styled from 'styled-components';
import BackgroundImage from 'gatsby-background-image';
import Menu from '../components/menu';

const StyledBackgroundSection = styled(BackgroundImage)`
  width: 100%;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  height: clamp(500px, 80vh, 650px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media (max-width: 767px) {
    justify-content: flex-start;
  }
`;

const Header = styled.header`
  color: var(--white);
  text-align: center;
  h1 {
    font-family: 'Abril Fatface';
    font-size: 4.375rem;
    margin-bottom: 2rem;
    text-transform: uppercase;
  }
  p {
    text-transform: uppercase;
    font-size: 1.25rem;
    font-weight: 500;
    line-height: 1.5;
    max-width: 70ch;
    margin-left: auto;
    margin-right: auto;
  }
  .text-content {
    padding: 6rem 0;
  }
  @media (max-width: 768px) {
    h1 {
      font-size: clamp(24px, 10vh, 48px);
    }
    p {
      font-size: 16px;
    }
    .text-content {
      padding: 2rem;
    }
  }
`;

const Qp1Header = ({ title, tagline, color }) => {
  const data = useStaticQuery(
    graphql`
      query {
        qp: file(relativePath: { eq: "qp1-hero.jpg" }) {
          childImageSharp {
            fluid(quality: 80, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `
  );
  const qp = data.qp.childImageSharp.fluid;
  return (
    <StyledBackgroundSection
      Tag="div"
      className=""
      fluid={qp}
      backgroundColor={`#586142`}
    >
      <Header>
        <Menu />
        <div className="container text-content">
          <h1>{title}</h1>
          <p>{tagline}</p>
        </div>
      </Header>
    </StyledBackgroundSection>
  );
};

export default Qp1Header;
