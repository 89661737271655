import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import styled from 'styled-components';

const AnimalSection = styled.section`
  background: var(--beije);
`;

const Heading = styled.div`
  padding: 5.875rem 0;
  h2 {
    color: var(--green);
    font-family: 'Abril Fatface';
    text-transform: uppercase;
    font-size: 3.125rem;
    padding-bottom: 5rem;
  }
`;

const AnimalProfile = styled.div`
  background-color: var(--green);
  color: var(--white);
  text-align: center;
  margin-top: -2rem;
  padding: 3rem 2rem;
  height: 20rem;
  display: grid;
  place-content: center;
  h3,
  p {
    margin-bottom: 1rem;
    line-height: 1.5;
  }
  h3 {
    font-weight: 800;
    font-size: 1.25rem;
    text-transform: uppercase;
  }
  p {
    font-size: 1.125rem;
  }
`;

const animals = () => {
  return (
    <AnimalSection>
      <Heading>
        <div className="container">
          <h2 className="center">Os Nossos Animais</h2>
          <div className="grid">
            <div className="col-md-4">
              <StaticImage
                src="../images/animal1.jpg"
                alt="Quinta dos Pastores I - Exterior 1"
              />
              <AnimalProfile>
                <h3>Ambrósio - o Bode</h3>
                <p>
                  A Quinta dos Pastores I não seria a mesma sem este{' '}
                  <strong>bode</strong>. O Ambrósio é grande, mas é um ótimo
                  amigo.{' '}
                  <strong>
                    Ele adora a presença das pessoas, adora que o alimentem e
                    adora jogar futebol.
                  </strong>
                </p>
              </AnimalProfile>
            </div>
            <div className="col-md-4">
              <StaticImage
                src="../images/animal2.jpg"
                alt="Quinta dos Pastores I - Exterior 1"
              />
              <AnimalProfile>
                <h3>Trump - O Faisão</h3>
                <p>
                  Ele é loiro, vaidoso, elegante e tem apenas uma esposa, à qual
                  é muito fiel.
                </p>
              </AnimalProfile>
            </div>
            <div className="col-md-4">
              <StaticImage
                src="../images/animal3.jpg"
                alt="Quinta dos Pastores I - Exterior 1"
              />
              <AnimalProfile>
                <h3>Lucy - a cabra</h3>
                <p>
                  Lucy é um dos animais mais antigos da Quinta. A Lucy adora
                  brincar, comer e dormir.
                </p>
              </AnimalProfile>
            </div>
          </div>
        </div>
      </Heading>
    </AnimalSection>
  );
};

export default animals;
