import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import styled from 'styled-components';
import BackgroundImage from 'gatsby-background-image';

const StyledBackgroundSection = styled(BackgroundImage)`
  width: 100%;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
`;

const ToDoSection = styled.section`
  background: var(--beije);
  @media (max-width: 767px) {
    .mobile-first {
      grid-row: 1;
    }
  }
`;

const Heading = styled.div`
  padding: 5.875rem 0;
  h2 {
    color: var(--brown);
    font-family: 'Abril Fatface';
    text-transform: uppercase;
    font-size: 3.125rem;
  }
`;

const Grid1 = styled.div`
  display: grid;
  grid-template-columns: 60% 40%;
  color: var(--light-green);
  background-color: var(--green);
  div {
    padding: 2rem;
  }
  @media (max-width: 767px) {
    grid-template-columns: 1fr;
    div {
      padding: 2rem;
    }
    div:first-of-type {
      min-height: 320px;
    }
  }
`;

const Grid2 = styled.div`
  display: grid;
  grid-template-columns: 40% 60%;
  background-color: var(--light-brown);
  div {
    padding: 1rem;
  }
  @media (max-width: 767px) {
    grid-template-columns: 1fr;
    div {
      padding: 2rem;
    }
    div:nth-of-type(2) {
      min-height: 320px;
    }
  }
`;

const GridData = styled.div`
  color: var(--light-green);
  width: 100%;
  min-height: 25rem;
  @media (max-width: 767px) {
    min-height: 5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 2rem 0;
  h3,
  p {
    margin-bottom: 1rem;
    max-width: 300px;
    @media (max-width: 767px) {
      max-width: 100%;
    }
  }
  h3 {
    font-size: 1.5rem;
    font-weight: 900;
    text-transform: uppercase;
  }
  p {
    font-weight: 400;
    font-size: 1.25rem;
    line-height: 1.5rem;
  }
  div {
    text-align: right;
    display: flex;
    flex-direction: column;
    align-items: end;
    @media (max-width: 767px) {
      padding: 0;
      text-align: left;
      justify-content: center;
      align-items: initial;
    }
  }
`;

const WhatToDoQP1 = () => {
  const data = useStaticQuery(
    graphql`
      query {
        qp: file(relativePath: { eq: "QP1/grid/qpgrid-1.jpg" }) {
          childImageSharp {
            fluid(quality: 80, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        qp2: file(relativePath: { eq: "QP1/grid/qpgrid-2.jpg" }) {
          childImageSharp {
            fluid(quality: 80, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        qp3: file(relativePath: { eq: "QP1/grid/qpgrid-3.jpg" }) {
          childImageSharp {
            fluid(quality: 80, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        qp4: file(relativePath: { eq: "QP1/grid/qpgrid-4.jpg" }) {
          childImageSharp {
            fluid(quality: 80, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        qp5: file(relativePath: { eq: "QP1/grid/qpgrid-5.jpg" }) {
          childImageSharp {
            fluid(quality: 80, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        qp6: file(relativePath: { eq: "QP1/grid/qpgrid-6.jpg" }) {
          childImageSharp {
            fluid(quality: 80, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        qp7: file(relativePath: { eq: "QP1/grid/qpgrid-7.jpg" }) {
          childImageSharp {
            fluid(quality: 80, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `
  );
  const qp = data.qp.childImageSharp.fluid;
  const qp2 = data.qp2.childImageSharp.fluid;
  const qp3 = data.qp3.childImageSharp.fluid;
  const qp4 = data.qp4.childImageSharp.fluid;
  const qp5 = data.qp5.childImageSharp.fluid;
  const qp6 = data.qp6.childImageSharp.fluid;
  const qp7 = data.qp7.childImageSharp.fluid;
  return (
    <ToDoSection>
      <Heading>
        <div className="container">
          <h2 className="center">O Que Fazer na Quinta</h2>
        </div>
      </Heading>
      <Grid1>
        <StyledBackgroundSection
          Tag="div"
          fluid={qp}
          backgroundColor={`#f1f2c9`}
        ></StyledBackgroundSection>
        <GridData>
          <h3>ALIMENTAR E CONVIVER COM OS NOSSOS ANIMAIS</h3>
          <p>
            Ambrósio, o bode, é a nossa mascote. Contudo, todos os nosso animais
            gostam de conviver e serem alimentados à mão. Venham conhecer Lucy -
            a cabra, Oscar- o peru, Trump - o faizão, Biden - o pavão, etc.
          </p>
        </GridData>
      </Grid1>
      <Grid2>
        <GridData>
          <div>
            <h3>MATRECOS</h3>
            <p>Sporting Vs Benfica! Quem vai ganhar?</p>
            <p>
              <strong>
                A nossa quinta dispõe de uma mesa de matrecos gratuita.
              </strong>
            </p>
          </div>
        </GridData>
        <StyledBackgroundSection
          Tag="div"
          className="mobile-first"
          fluid={qp2}
          backgroundColor={`#f1f2c9`}
        ></StyledBackgroundSection>
      </Grid2>
      <Grid1>
        <StyledBackgroundSection
          Tag="div"
          fluid={qp3}
          backgroundColor={`#f1f2c9`}
        ></StyledBackgroundSection>
        <GridData>
          <h3>SNOOKER</h3>
          <p>
            Quem perde vai à piscina.... Seja no inverno ou verão. Esta é a
            regra da casa.
          </p>
          <p>
            <strong>
              A sala de estar dispõe de uma mesa de snooker para relaxar.
            </strong>
          </p>
        </GridData>
      </Grid1>
      <Grid2>
        <GridData>
          <div>
            <h3>JOGO DA MACACA</h3>
            <p>Quem se lembra do jogo da macaca?</p>
            <p>
              <strong>
                Além deste jogo, a Quinta dos Pastores tem também um Jenga,
                Petanca, etc.
              </strong>
            </p>
          </div>
        </GridData>
        <StyledBackgroundSection
          Tag="div"
          className="mobile-first"
          fluid={qp4}
          backgroundColor={`#f1f2c9`}
        ></StyledBackgroundSection>
      </Grid2>
      <Grid1>
        <StyledBackgroundSection
          Tag="div"
          fluid={qp5}
          backgroundColor={`#f1f2c9`}
        ></StyledBackgroundSection>
        <GridData>
          <h3>BALOIÇOS</h3>
          <p>
            Na nossa quinta pode fazer uma sesta na cama-baloiço, escutando os
            passarinhos.
          </p>
          <p>
            Além disso, também temos 2 baloiços tradicionais para crianças e
            adultos.
          </p>
        </GridData>
      </Grid1>
      <Grid2>
        <GridData>
          <div>
            <h3>BICICLETAS</h3>
            <p>
              Deixamos ao vosso dispor 3 Bicicletas para que passeiem pela
              natureza do Ribatejo, para ir até ao mercado ou para fazerem
              exercício.
            </p>
          </div>
        </GridData>
        <StyledBackgroundSection
          Tag="div"
          className="mobile-first"
          fluid={qp6}
          backgroundColor={`#f1f2c9`}
        ></StyledBackgroundSection>
      </Grid2>
      <Grid1>
        <StyledBackgroundSection
          Tag="div"
          fluid={qp7}
          backgroundColor={`#f1f2c9`}
        ></StyledBackgroundSection>
        <GridData>
          <h3>SAUNA</h3>
          <p>
            A sauna é uma das terapias mais relaxantes e benéficas para o corpo
            humano.
          </p>
          <p>
            Na Quinta dos Pastores I podes ter acesso a uma excelente sauna.
            Podes também combinar uma sessão de relaxamento da sauna com um
            banho na piscina.
          </p>
        </GridData>
      </Grid1>
    </ToDoSection>
  );
};

export default WhatToDoQP1;
